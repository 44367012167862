import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@pofd-front/storybook';
import { setMobileSearchFlag } from 'store/mobileSearchSlice';
import useSearchCoupons from 'shared/hooks/use-search-coupons';

import searchIcon from 'images/icons/search-icon.svg';

import { ICoupons } from 'interfaces';
import { COUPONS_ALL_ID } from 'utils';
import { RootState } from 'store';
import {
  setFilteredCouponsList,
  setNextPage,
} from 'store/filteredCouponsListSlice';
import styles from './search.module.scss';
import SearchResult from './search-result';

const html = document.querySelector('html');
const body = document.querySelector('body');

let currentInputValue = '';

interface ISearch {
  openMobileSearch: boolean;
  smartBannerFlag: boolean;
}

const Search: FC<ISearch> = ({
  openMobileSearch,
  smartBannerFlag,
}) => {
  const { searchCoupons } = useSearchCoupons();
  const [inputVal, setInputVal] = useState('');
  const [openFlag, setOpenFlag] = useState(false);
  const [filteredCoupons, setFilteredCoupons] = useState<ICoupons[]>(
    [],
  );
  const [mobSearchFlag, setMobSearchFlag] = useState(false);
  const [inputFocusFlag, setInputFocusFlag] = useState(false);
  const rootEl = useRef(null);
  const [totalBtnSize, setTotalBtnSize] = useState(false);
  const navigate = useNavigate();
  const { posObjData } = useSelector(
    (state: RootState) => state.userPosition,
  );
  const { cityName, regionName, regionCode } = posObjData;

  const { userLogIn } = useSelector(
    (state: RootState) => state.userLogIn,
  );

  const { accessKey, isCouponsLoading } = useSelector(
    (state: RootState) => state.couponsAndCategoriesList,
  );

  const dispatch = useDispatch();

  const ref: React.RefObject<HTMLElement> = rootEl;

  const closeResult = () => {
    if (openFlag) {
      if (mobSearchFlag) {
        dispatch(setMobileSearchFlag(false));
      }
      setOpenFlag(false);
      setInputFocusFlag(false);
      body!.style.overflow = 'inherit';
      html!.style.overflow = 'inherit';
    }
  };

  const handleBtnClearInputClick = () => {
    setFilteredCoupons([]);
    setInputVal('');
    if (openFlag) {
      setOpenFlag(false);
    }
    setTimeout(() => {
      document
        .querySelector<HTMLInputElement>('#searchInput')
        ?.focus();
    }, 100);
  };

  const handleSearchBtnClick = useCallback(() => {
    if (inputVal.length >= 1) {
      dispatch(setFilteredCouponsList(filteredCoupons));
      document
        .querySelector<HTMLInputElement>('#searchInput')
        ?.blur();
      navigate(`/search?search_text=${inputVal}`);
      closeResult();
      setInputFocusFlag(false);
    }
  }, [inputVal, navigate, filteredCoupons, dispatch]);

  const handleMobSearchOpenFlagToggle = () => {
    if (inputFocusFlag) {
      setInputFocusFlag(false);
    }
    if (inputVal.length >= 1) {
      handleSearchBtnClick();
    } else if (openFlag) {
      closeResult();
    } else {
      setTimeout(() => {
        document
          .querySelector<HTMLInputElement>('#searchInput')
          ?.focus();
      }, 100);
    }
    dispatch(setMobileSearchFlag(!openMobileSearch));
  };

  const handleInputBlur = () => {
    if (openFlag) {
      closeResult();
      setInputFocusFlag(false);
      body!.style.overflow = 'inherit';
      html!.style.overflow = 'inherit';
    }
  };

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (
        ref.current &&
        !ref.current.contains(target) &&
        !target.closest('button') &&
        !target.closest('ul')
      ) {
        if (window.innerWidth < 768) {
          dispatch(setMobileSearchFlag(false));
        }

        handleInputBlur();
      }
    };

    document.addEventListener('mousedown', onClick);
    return () => document.removeEventListener('mousedown', onClick);
  }, []);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth > 768) {
        setMobSearchFlag(false);
        setTotalBtnSize(true);
      } else {
        setMobSearchFlag(true);
        setTotalBtnSize(false);
      }
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const val = e.target.value.toLowerCase();
    setInputVal(val);
    if (window.location.pathname.includes('search')) {
      const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?search_text=${val}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }

    if (val.length) {
      if (val.length >= 1 && !isCouponsLoading) {
        setOpenFlag(true);
        body!.style.overflow = 'hidden';
        html!.style.overflow = 'hidden';
        const response = await searchCoupons({
          city_name: cityName,
          region_name: regionName !== undefined ? regionName : '',
          region_code: regionCode,
          page: '0',
          access_key: accessKey,
          size: '24',
          shopOrTitleName: val.toLowerCase(),
          banner_id: String(COUPONS_ALL_ID),
        });

        setFilteredCoupons(response.page.content);
      }
    } else {
      setOpenFlag(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (isCouponsLoading) {
        currentInputValue = inputVal;
      }
      if (!isCouponsLoading && openFlag) {
        if (currentInputValue !== inputVal) {
          dispatch(setNextPage(0));
          const response = await searchCoupons({
            city_name: cityName,
            region_name: regionName !== undefined ? regionName : '',
            region_code: regionCode,
            page: '0',
            access_key: accessKey,
            size: '24',
            shopOrTitleName: inputVal.toLowerCase(),
            banner_id: String(COUPONS_ALL_ID),
          });

          setFilteredCoupons(response.page.content);
        }
      }
    })();
  }, [isCouponsLoading]);

  const handleInputFocus = () => {
    setInputFocusFlag(true);
    body!.style.overflow = 'hidden';
    html!.style.overflow = 'hidden';
  };

  const handleKeyDownForm = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Enter') {
      handleSearchBtnClick();
    }
  };

  return (
    <div
      className={`${styles.search} ${
        userLogIn ? styles.fullwidth : ''
      } 'position-relative'`}
    >
      <div ref={rootEl} className="search__wrap">
        <div className={styles.form}>
          <div>
            <label
              htmlFor="searchInput"
              className="position-relative d-flex align-items-center"
            >
              <input
                data-testid="search-input-1"
                onKeyDown={handleKeyDownForm}
                id="searchInput"
                onInput={handleInputChange}
                onFocus={handleInputFocus}
                autoComplete="off"
                value={inputVal}
                type="text"
                className={`${styles.input} ${
                  openMobileSearch ? styles['open-mob-search'] : ''
                }`}
                placeholder="Название компании или акции"
              />
              {inputVal.length ? (
                <div className={styles['clear-text-btn']}>
                  <Button
                    styleType="invisible"
                    product="default"
                    type="button"
                    leftIcon="cross"
                    iconStroke
                    size="medium"
                    onClick={handleBtnClearInputClick}
                    label=""
                    theme="common"
                  />
                </div>
              ) : null}
            </label>
          </div>
          <button
            data-testid="search-button-1"
            onClick={
              mobSearchFlag
                ? handleMobSearchOpenFlagToggle
                : handleSearchBtnClick
            }
            type="button"
            className={`${styles.btn} d-flex align-items-center justify-content-center`}
          >
            <img src={searchIcon} alt="search" />
          </button>
        </div>
      </div>
      {inputFocusFlag && (
        <div
          className={`${styles.overlay} ${
            smartBannerFlag ? styles['banner-active'] : ''
          }`}
        />
      )}
      {openFlag && (
        <div
          className={`${styles.result} ${
            smartBannerFlag ? styles['banner-active'] : ''
          }`}
        >
          <ul
            className={`${styles['result-list']} ${
              inputFocusFlag && mobSearchFlag
                ? styles['result-list--mob-height']
                : ''
            }`}
          >
            {filteredCoupons?.length ? (
              <>
                {filteredCoupons.map((item: ICoupons) => (
                  <li
                    className={styles['result-item']}
                    key={item.couponId}
                  >
                    <SearchResult elem={item} func={closeResult} />
                  </li>
                ))}
              </>
            ) : (
              <p className={styles['none-result']}>
                {filteredCoupons?.length === 0 && !isCouponsLoading
                  ? 'Нет данных'
                  : 'Идет поиск'}
              </p>
            )}
            {filteredCoupons!.length > 23 && (
              <div className={styles['result-total-btn']}>
                <Button
                  styleType="secondary"
                  type="button"
                  size={totalBtnSize ? 'medium' : 'large'}
                  product="ofd"
                  onClick={handleSearchBtnClick}
                  label="Все результаты"
                  theme="common"
                />
              </div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Search;
