import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Helmet from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import CardPagePreloader from '../components/content-preloader/card-page-preloader';
import { PROMO_URL } from '../urls';

import CouponModal from '../components/coupon-modal/coupon-modal';

import { setModalFlag } from '../store/modalSlice';
import { setErrorModal } from '../store/errorModalSlice';

import styles from './card-page.module.scss';
import { ICoupons } from '../interfaces';
import { RootState } from '../store';
import { setCouponsList } from '../store/couponsAndCategoriesListSlice';

import copyIcon from '../images/icons/copy.svg';
import Coupon from '../components/coupon/coupon';
import { declensionNum } from '../utils';
import { DistributedDataResponse } from '../shared/dto/coupons-dto';

const TEXT_LENGTH = 4;

const cx = classNames.bind(styles);

declare const window: any;

const month = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

interface IYmParams {
  couponProviderId: string;
  shopName: string;
  couponId: string;
  title: string;
  isCouponCodeNeeded: boolean;
  shopCategories: string[];
  place: string;
}

const ymParams: IYmParams = {
  couponProviderId: '',
  shopName: '',
  couponId: '',
  title: '',
  isCouponCodeNeeded: false,
  shopCategories: [],
  place: '',
};

interface ICardButton {
  promo: null | DistributedDataResponse;
  onClick: () => void;
  onOpenSiteButtonClick: (place: string) => void;
  loading: boolean;
  isCouponCodeNeeded: boolean | undefined;
}

const CardButton: FC<ICardButton> = ({
  promo,
  loading,
  onOpenSiteButtonClick,
  onClick,
  isCouponCodeNeeded,
}) => {
  switch (true) {
    case promo !== null || !isCouponCodeNeeded:
      return (
        <button
          type="button"
          className={styles.btn}
          onClick={() => onOpenSiteButtonClick('page')}
        >
          {promo !== null && promo?.coupon.buttonText !== null
            ? promo?.coupon.buttonText
            : 'Перейти на сайт'}
        </button>
      );

    case promo === null || isCouponCodeNeeded:
      return (
        <div
          aria-hidden
          className={styles.promo}
          data-testid="card_page-click-1"
          onClick={onClick}
          role="button"
        >
          {!loading ? 'Получить промокод' : 'Загрузка...'}
        </div>
      );

    default:
      return null;
  }
};

let promoCode = '';

const CardPage: FC = () => {
  const { id } = useParams();
  const [card, setCard] = useState<ICoupons>();
  const [promo, setPromo] = useState<DistributedDataResponse | null>(
    null,
  );
  const [shopUrl, setShopUrl] = useState<string | undefined>('');
  const [couponProviderId, setCouponProviderId] = useState(null);
  const [toggleModalFlag, setToggleModalFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [successCopy, setSuccessCopy] = useState(false);
  const [clickPromoBtnFlag, setClickPromoBtnFlag] = useState(false);
  const selectUserLogged = (state: RootState) => state.userLogIn;
  const userLogged = useSelector(selectUserLogged);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { accessKey, couponList } = useSelector(
    (state: RootState) => state.couponsAndCategoriesList,
  );

  useEffect(() => {
    if (couponList.length) {
      const targetUrl = couponList.filter(
        (coupon) => coupon.couponId === Number(id),
      )[0];

      if (!targetUrl || targetUrl === undefined) {
        dispatch(
          setErrorModal({
            errorCode: 422,
            fullwidth: false,
            itemId: id,
          }),
        );
        dispatch(setModalFlag({ modal: true, def: 'error' }));
        navigate('/');
      }
    }
  }, [couponList]);

  useEffect(() => {
    if (couponList.length) {
      const specificCard = couponList.filter(
        (item: ICoupons) => item.couponId === Number(id),
      );

      if (specificCard.length) {
        ymParams.shopCategories = [];
        setCouponProviderId(specificCard[0].couponProviderId);
        ymParams.couponProviderId = specificCard[0].couponProviderId;
        ymParams.isCouponCodeNeeded =
          specificCard[0].isCouponCodeNeeded;
        specificCard[0].shopCategories.forEach((category) => {
          ymParams.shopCategories.push(category.name);
        });

        if (specificCard[0].linkToShop) {
          setShopUrl(specificCard[0].linkToShop);
        } else {
          setShopUrl(specificCard[0].shopUrl);
        }
        const groupCoupons = couponList.filter(
          (coupon) =>
            coupon.shop.id === specificCard[0].shop.id &&
            coupon.couponId !== specificCard[0].couponId,
        );
        const newCoupons = {
          ...specificCard[0],
          couponSameStore: groupCoupons,
        };
        setCard(newCoupons);
      }
    }
  }, [id, couponList]);

  const deadLine = useCallback((date: string) => {
    const deadlineDate = new Date(
      new Date(date).setUTCHours(20, 59, 59, 999),
    );
    const currDate = new Date();
    const deadlineDay = deadlineDate.getDate();
    const deadlineMonth = month[deadlineDate.getMonth()];

    const deadline = deadlineDate.getTime() - currDate.getTime();
    const days = Math.floor(deadline / 1000 / 60 / 60 / 24);
    const hours = Math.floor(deadline / 1000 / 60 / 60) % 24;
    const minutes = Math.floor(deadline / 1000 / 60) % 60;

    switch (true) {
      case minutes === 0 && hours === 0 && days === 0:
        return <div> Акция закончилась </div>;
      case days === 0 && hours === 0:
        return (
          <div className={styles.deadline_error}>
            {minutes > 1 ? 'Осталось' : 'Осталась'} {minutes}{' '}
            {declensionNum(minutes, ['минута', 'минуты', 'минут'])}{' '}
          </div>
        );
      case days === 0:
        return (
          <div className={styles.deadline_error}>
            {' '}
            {hours % 10 === 1 && hours !== 11
              ? 'Остался'
              : 'Осталось'}{' '}
            {hours} {declensionNum(hours, ['час', 'часа', 'часов'])}{' '}
          </div>
        );
      case days > 0 && days <= 7:
        return (
          <div
            className={`${
              days > 1
                ? styles.deadline_warning
                : styles.deadline_error
            }`}
          >
            {' '}
            {days > 1 ? 'Осталось' : 'Остался'} {days}{' '}
            {declensionNum(days, ['день', 'дня', 'дней'])}{' '}
          </div>
        );
      case minutes <= 0:
        return (
          <div className={styles.deadline_error}>Последний день</div>
        );
      default:
        return (
          <div>
            {' '}
            до {deadlineDay} {deadlineMonth}
          </div>
        );
    }
  }, []);

  const handleGetCoupon = useCallback(async () => {
    setClickPromoBtnFlag(true);
    if (userLogged.userLogIn) {
      setLoading(true);
      setErrorMessage('');
      setPromo(null);
      try {
        const promoResponse = await fetch(
          `${PROMO_URL}?access_key=${accessKey}&coupon_provider_id=${couponProviderId}&coupon_id=${id}`,
          {
            headers: {
              Authorization: localStorage.session_token,
            },
          },
        );
        dispatch(
          setErrorModal({
            errorCode: promoResponse.status,
            fullwidth: false,
            itemId: null,
          }),
        );
        let newPromo: React.SetStateAction<any>;
        switch (promoResponse.status) {
          case 404:
            dispatch(
              setErrorModal({
                errorCode: 422,
                fullwidth: false,
                itemId: null,
              }),
            );
            dispatch(
              setCouponsList(
                couponList.filter(
                  (coupon) => coupon.couponId !== Number(id),
                ),
              ),
            );
            dispatch(setModalFlag({ modal: true, def: 'error' }));
            break;
          case 422:
            dispatch(
              setErrorModal({
                errorCode: promoResponse.status,
                fullwidth: false,
                itemId: null,
              }),
            );
            dispatch(setModalFlag({ modal: true, def: 'error' }));
            break;
          case 401:
            dispatch(setModalFlag({ modal: true, def: 'rereg' }));
            break;
          case 200:
            localStorage.setItem('access_key_is_distributed', 'true');
            newPromo = await promoResponse.json();
            setPromo(newPromo);
            setShopUrl(newPromo.linkToShop);
            if (id != null) {
              ymParams.couponId = id;
            }
            ymParams.shopName = newPromo?.coupon.shopName;
            ymParams.title = newPromo?.coupon.title;

            window.ym(85926744, 'reachGoal', 'popup-promo', ymParams);
            break;
          default:
            dispatch(
              setErrorModal({
                errorCode: promoResponse.status,
                fullwidth: false,
                itemId: null,
              }),
            );
            dispatch(setModalFlag({ modal: true, def: 'error' }));
        }
      } catch (error) {
        dispatch(
          setErrorModal({
            errorCode: null,
            fullwidth: false,
            itemId: null,
          }),
        );
        setErrorMessage('Ошибка получения купона');
        dispatch(setModalFlag({ modal: true, def: 'error' }));
      } finally {
        setLoading(false);
        setClickPromoBtnFlag(false);
      }
    } else {
      dispatch(setModalFlag({ modal: true, def: 'rereg' }));
    }
  }, [dispatch, couponProviderId, id, userLogged]);

  useEffect(() => {
    if (userLogged.userLogIn && clickPromoBtnFlag) {
      handleGetCoupon();
    }
  }, [userLogged, handleGetCoupon, clickPromoBtnFlag]);

  const handleCloseModal = useCallback(() => {
    setToggleModalFlag(false);
  }, []);

  useEffect(() => {
    if (promo) {
      const {
        promoCode: { code },
      } = promo;
      setToggleModalFlag(true);
      promoCode = code;
    }
  }, [promo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenSiteButtonClick = useCallback(
    (place: string) => {
      if (shopUrl) {
        ymParams.place = place;
        window.ym(85926744, 'reachGoal', 'go-site', ymParams);
        window.open(shopUrl, '_blank');
      }
    },
    [shopUrl],
  );

  const copyToClipboard = (value: string) => {
    if (
      navigator &&
      navigator.clipboard &&
      navigator.clipboard.writeText
    ) {
      return navigator.clipboard.writeText(value);
    }
    return Promise.reject(
      new Error('The Clipboard API is not available.'),
    );
  };

  const handleCopyBtnClick = useCallback(() => {
    setSuccessCopy(true);
    copyToClipboard(promoCode);
    setTimeout(() => {
      setSuccessCopy(false);
    }, 3500);
  }, []);

  return (
    <div className="card-page mt-5">
      <Helmet title={card?.title}>
        <meta
          name="description"
          content={`${card?.shortDescription}`}
        />
      </Helmet>
      {card ? (
        <div className={`${styles.wrap}`}>
          <div
            className={`${styles.main} ${
              card?.limitations === '' &&
              card.orderRestrictions === '' &&
              card.detailText === '' &&
              card.detailText.length < TEXT_LENGTH &&
              card.shopDescription === ''
                ? 'justify-content-center'
                : ''
            }`}
          >
            <div className={styles['block-info']}>
              {card?.pictures[0] !== '' && (
                <div className={styles.pic}>
                  <img src={card?.pictures} alt="card logo" />
                  <div className={styles['pic-shadow']} />
                </div>
              )}
              <div className={styles['block-wrap']}>
                <div>
                  <div
                    className={`${styles.img} ${
                      card?.couponProviderId === 5
                        ? styles.square_pic
                        : ''
                    }`}
                  >
                    <img
                      src={
                        card?.altLogo
                          ? card.altLogo
                          : card?.shopLogoLink
                      }
                      alt="coupon logo"
                    />
                  </div>
                  <p className={`${styles['shop-name']}`}>
                    {card?.shopName}
                  </p>
                </div>
                <div className={styles.divider}>
                  <div className={styles.left_divider}>
                    <div />
                  </div>
                  <div className={styles.middle_divider} />
                  <div className={styles.right_divider}>
                    <div />
                  </div>
                </div>
                <div className={styles.text_block}>
                  <p className={styles.title}>{card?.title}</p>
                  {card?.shortDescription && (
                    <div className={`${styles.desc}`}>
                      {card?.shortDescription}
                    </div>
                  )}
                  {card?.endDt !== null && (
                    <div className={styles.deadline}>
                      {deadLine(card!.endDt)}
                    </div>
                  )}
                  {promo && (
                    <div className={cx('promocode-wrapper')}>
                      <input
                        data-testid="coupon_modal-input-1"
                        type="text"
                        size={20}
                        className={cx('promocode')}
                        value={promoCode}
                        readOnly
                      />
                      {successCopy ? (
                        <div className={cx('clipboard-success')} />
                      ) : (
                        <div className={cx('copy-btn_wrap')}>
                          <button
                            onClick={handleCopyBtnClick}
                            className={cx('copy-btn')}
                            type="button"
                          >
                            <img src={copyIcon} alt="copy" />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {Boolean(
                    promo !== null &&
                      promo?.coupon.applyInstruction !== null,
                  ) && (
                    <div className="mt-4">
                      <h3 className={`${styles.subtitle} mb-3`}>
                        Инструкция по применению
                      </h3>
                      <div>{promo?.coupon.applyInstruction}</div>
                    </div>
                  )}
                  <div className="mt-4">
                    <CardButton
                      promo={promo}
                      loading={loading}
                      onClick={handleGetCoupon}
                      onOpenSiteButtonClick={
                        handleOpenSiteButtonClick
                      }
                      isCouponCodeNeeded={card?.isCouponCodeNeeded}
                    />
                    {Boolean(errorMessage.length) && (
                      <div className={styles.error}>
                        {errorMessage}
                      </div>
                    )}
                    {Boolean(card?.promo) && (
                      <div className={styles.date}>{card?.date}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {card?.limitations !== '' ||
            card.orderRestrictions !== '' ||
            card?.shopDescription !== '' ||
            (card.detailText !== '' &&
              card.detailText.length > TEXT_LENGTH) ? (
              <div className={styles['block-wrap-desc']}>
                <div className={styles['block-desc']}>
                  {card?.limitations !== '' ||
                  card.orderRestrictions !== '' ? (
                    <div className={styles.detail}>
                      <div
                        className={styles.detail}
                        dangerouslySetInnerHTML={{
                          __html: card!.limitations,
                        }}
                      />
                      <div
                        className={styles.detail}
                        dangerouslySetInnerHTML={{
                          __html: card!.orderRestrictions,
                        }}
                      />
                    </div>
                  ) : null}
                  {card?.detailText !== '' &&
                  card!.detailText.length > TEXT_LENGTH ? (
                    <div>
                      {card!.limitations !== '' ||
                      card!.orderRestrictions !== '' ? (
                        <div className={`${styles.desc_divider}`} />
                      ) : null}
                      <h3 className={`${styles.subtitle} mb-3`}>
                        Подробнее
                      </h3>
                      <div
                        className={styles.detail}
                        dangerouslySetInnerHTML={{
                          __html: card!.detailText,
                        }}
                      />
                    </div>
                  ) : null}
                  {card?.shopDescription !== '' &&
                    card?.shopDescription !== null && (
                      <div>
                        {(card?.detailText !== '' &&
                          card!.detailText.length > TEXT_LENGTH) ||
                        card!.limitations !== '' ||
                        card!.orderRestrictions !== '' ? (
                          <div className={`${styles.desc_divider}`} />
                        ) : null}
                        <h3 className={`${styles.subtitle} mb-3`}>
                          О магазине
                        </h3>
                        <div
                          className={styles.detail}
                          dangerouslySetInnerHTML={{
                            __html: card!.shopDescription,
                          }}
                        />
                      </div>
                    )}
                </div>
                {card?.couponSameStore &&
                card?.couponSameStore.length ? (
                  <div>
                    <h3 className={`${styles.subtitle} mb-3`}>
                      Другие акции магазина
                    </h3>
                    <ul
                      className={`${styles.coupon_list} d-block d-sm-flex flex-wrap`}
                    >
                      {card.couponSameStore.map((item) => (
                        <li
                          className={`${styles.coupon_item}`}
                          key={item.couponId}
                        >
                          <Coupon data={item} />
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <CardPagePreloader />
      )}

      {toggleModalFlag && (
        <CouponModal
          onOpenSiteButtonClick={handleOpenSiteButtonClick}
          promo={promo}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default CardPage;
