import React, { FC, useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Button } from '@pofd-front/storybook';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import type { RootState } from 'store';
import Home from 'pages/home';
import CardPage from 'pages/card-page';
import SearchResultPage from 'pages/search-result-page';
import NotFound404 from 'pages/not-found-404';
import ForgotPass from 'pages/forgotPass';
import FeedbackPage from 'pages/feedback-page';
import PolicyPage from 'pages/policy-page';
import TermsPage from 'pages/terms-page';
import RedirectPage from 'pages/redirect-page';
import ShopPage from 'pages/shop-page';
import Spinner from '../spinner/spinner';
import Wrapper from '../wrapper/wrapper';
import ErrorModals from '../error-modals/error-modals';
import EmailConfirmedPage from '../../pages/email-confirmed-page';
import Iframe from '../iframe/iframe';
import McoAgreementPage from '../../pages/mco-agreement-page';
import SmartBanner from '../smart-banner/smart-banner';
import RequiredAuthModal from '../required-auth-modal/required-auth-modal';
import AuthModal from '../auth-modal/auth-modal';
import styles from './app.module.scss';
import AppFooter from '../app-footer/app-footer';
import AppHeader from '../app-header/app-header';

const tagManagerArgs = {
  gtmId: 'GTM-M5SBVDD',
};

TagManager.initialize(tagManagerArgs);

const url: string = document.location.href;

interface IContentModal {
  val: string | null;
}

const ContentModal: FC<IContentModal> = ({ val }) => {
  switch (val) {
    case 'auth':
      return <AuthModal userSignUp={false} />;
    case 'reg':
      return <AuthModal userSignUp />;
    case 'rereg':
      return <RequiredAuthModal />;
    default:
      return <ErrorModals />;
  }
};

declare const window: any;

const { dataLayer } = window;

const App = () => {
  dataLayer.push({
    event: 'pageview',
  });

  const [floctoryFlag, setFloctoryFlag] = useState(false);
  const [ssid, setSsid] = useState('');
  const [bid, setBid] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');
  const [scrollBtnFlag, setScrollBtnFlag] = useState(false);
  const [smartBannerFlag, setSmartBannerFlag] = useState(false);
  const selectModal = (state: RootState) => state.modal;
  const modal = useSelector(selectModal);
  const { isCouponsLoading } = useSelector(
    (state: RootState) => state.couponsAndCategoriesList,
  );

  const handleSmartBannerClose = () => {
    setSmartBannerFlag(false);
  };

  useEffect(() => {
    (async () => {
      if (url.indexOf('ssid') !== -1 && url.indexOf('bid') !== -1) {
        setFloctoryFlag(true);
        setSsid(url.split('ssid=')[1].split('&')[0]);
        setBid(url.split('bid=')[1].split('&')[0]);
        setCurrentUrl(url);
      }
    })();
  }, []);

  const handleScrollBtnClick = useCallback(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const windowScroll = () => {
      const scroll = window.scrollY;
      if (scroll > document.documentElement.clientHeight) {
        setScrollBtnFlag(true);
      } else {
        setScrollBtnFlag(false);
      }
    };
    window.addEventListener('scroll', windowScroll);
    return () => window.removeEventListener('scroll', windowScroll);
  }, []);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth < 480) {
        setSmartBannerFlag(true);
      } else {
        setSmartBannerFlag(false);
      }
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <>
      {smartBannerFlag ? (
        <SmartBanner onSmartBannerClose={handleSmartBannerClose} />
      ) : null}
      <div
        className={`${styles.wrapper} ${
          smartBannerFlag ? styles.indent : ''
        }`}
      >
        <AppHeader
          floctoryFlag={floctoryFlag}
          currentUrl={currentUrl}
          smartBannerFlag={smartBannerFlag}
        />
        {floctoryFlag ? (
          <Iframe ssid={ssid} bid={bid} />
        ) : (
          <div className={styles.main}>
            <Container>
              <Routes>
                <Route path="/" element={<Wrapper />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/gift/:id" element={<CardPage />} />
                  <Route path="/shop/:id" element={<ShopPage />} />
                  <Route
                    path="/search"
                    element={<SearchResultPage />}
                  />
                </Route>

                <Route path="*" element={<NotFound404 />} />
                <Route path="forgot-pass" element={<ForgotPass />} />
                <Route path="feedback" element={<FeedbackPage />} />
                <Route path="policy" element={<PolicyPage />} />
                <Route path="terms" element={<TermsPage />} />
                <Route
                  path="mco_agreement"
                  element={<McoAgreementPage />}
                />
                <Route
                  path="email-confirmed"
                  element={<EmailConfirmedPage />}
                />
                <Route
                  path="redirect_store"
                  element={<RedirectPage />}
                />
              </Routes>
              {scrollBtnFlag && (
                <div className={styles.app_btn}>
                  <Button
                    styleType="borderless"
                    type="button"
                    onClick={() => {
                      handleScrollBtnClick();
                    }}
                    disabled={false}
                    leftIcon="arrowUp"
                    product="ofd"
                    testId="authorization_info_modal_content-click-1"
                    size="large"
                    iconStroke
                    label=""
                    theme="common"
                  />
                </div>
              )}
            </Container>
          </div>
        )}
        <AppFooter />
        {modal.modal ? <ContentModal val={modal.definition} /> : null}
        {isCouponsLoading && <Spinner />}
      </div>
    </>
  );
};

export default App;
