import React from 'react';
import { Helmet } from 'react-helmet';

import './terms-page.module.scss';

const McoAgreementPage = () => (
  <section
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Helmet title="СОГЛАСИЕ НА ОБРАБОТКУ ДАННЫХ">
      <meta
        name="description"
        content="Страница пользовательского соглашения"
      />
    </Helmet>
    <h2>СОГЛАСИЕ НА ОБРАБОТКУ ДАННЫХ</h2>
    <section>
      <p>
        Физическое лицо, являясь пользователем сайта{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://podarocheck.ru/"
        >
          https://podarocheck.ru/
        </a>
        (включая мобильное приложение), действуя свободно, своей волей
        и в своем интересе, а также подтверждая свою дееспособность,
        предоставляет свое согласие (далее – «Согласие») Обществу с
        ограниченной ответственностью «Эвотор ОФД» (ОГРН 1167746512856
        ИНН 9715260691,{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="mailto:pdn@platformaofd.ru"
        >
          pdn@platformaofd.ru
        </a>
        , +7(495)252-50-50), которому принадлежит веб-сайт{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://podarocheck.ru/"
        >
          https://podarocheck.ru/
        </a>
        , а также мобильное приложение «ПодароЧек», и которое
        зарегистрировано по адресу 119048, г. Москва, ул. Усачева, д.
        33 стр.1 (далее – «Оператор»), на обработку своих персональных
        данных на следующих условиях:
      </p>
      <section>
        <p>
          1. Данное Согласие дается на обработку персональных данных,
          как без использования средств автоматизации, так и с их
          использованием.
        </p>
      </section>
      <section>
        <p>
          2. Согласие дается на обработку следующих моих персональных
          данных: персональные данные, не относящиеся к специальной
          категории персональных данных или к биометрическим
          персональным данным: адрес электронной почты (e-mail); номер
          мобильного телефона; время направления одноразового кода
          и/или кода доступа (если применимо) на абонентский номер
          и/или пользовательское устройство; IP-адрес; тип устройства;
          версия используемого приложения; название пользовательского
          устройства.
        </p>
      </section>
      <section>
        <p>3. Цель обработки персональных данных:</p>
        <section>
          <p>
            3.1. запрос и получение от ФНС России, в том числе через
            Сервис ФНС России «Мои чеки онлайн», кассовых чеков в
            электронной форме, сформированных контрольно-кассовой
            техникой организации или индивидуального предпринимателя
            (продавца) в момент совершения физическим лицом расчетов,
            в том числе с использованием платежных карт, (далее —
            чеки);
          </p>
        </section>
        <section>
          <p>
            3.2. регистрация в сервисах на сайте{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://podarocheck.ru/"
            >
              https://podarocheck.ru/
            </a>
            , и мобильном приложении «ПодароЧек», и их использование,
            включая возможность доступа к чекам и содержащейся в них
            информации.
          </p>
        </section>
      </section>
      <section>
        <p>
          4. В ходе обработки с персональными данными будут совершены
          следующие действия: сбор, запись, систематизация,
          накопление, хранение, уточнение (обновление, изменение),
          извлечение, использование, передача (предоставление,
          доступ), обезличивание, блокирование, удаление, уничтожение.
        </p>
      </section>
      <section>
        <p>
          5. Одновременно с предоставлением Согласия физическое лицо
          поручает Оператору при наличии технической возможности
          направлять запросы и получать от ФНС России все чеки,
          выданные после предоставления настоящего Согласия, а также в
          течение 3 (трех) календарных дней, предшествующих дате
          предоставления настоящего Согласия, и размещать полученные
          от ФНС России чеки в аккаунте физического лица, созданном на
          сайте{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://podarocheck.ru/"
          >
            https://podarocheck.ru/
          </a>
          , и мобильного приложения «ПодароЧек».
        </p>
      </section>
      <section>
        <p>
          6. Персональные данные обрабатываются: для цели, указанной в
          п. 3.1. настоящего согласия - до получения отказа субъекта
          персональных данных; для цели, указанной в п. 3.2.
          настоящего согласия, в течение срока использования сервисов
          Оператора, а также в течение 5 лет с момента последнего
          использования соответствующего сервиса на сайте{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://podarocheck.ru/"
          >
            https://podarocheck.ru/
          </a>
          , если иной срок не предусмотрен действующим
          законодательством РФ.
        </p>
      </section>
      <section>
        <p>
          7. Согласие может быть отозвано самим физическим лицом или
          его представителем путем направления ООО «Эвотор ОФД»
          письменного заявления или электронного заявления,
          подписанного согласно законодательству Российской Федерации
          в области электронной подписи, по адресу, указанному в
          начале Согласия.
        </p>
      </section>
      <section>
        <p>
          8. В случае отзыва физическим лицом Согласия ООО «Эвотор
          ОФД» вправе продолжить обработку персональных данных без
          него при наличии оснований, указанных в пунктах 2 – 11 части
          1 статьи 6, части 2 статьи 10 и части 2 статьи 11
          Федерального закона №152-ФЗ «О персональных данных» от
          27.07.2006 г.
        </p>
      </section>
      <section>
        <p>
          9. Настоящее согласие действует все время до момента
          прекращения обработки персональных данных, указанных в п.6 и
          п.7 Согласия.
        </p>
      </section>
    </section>
  </section>
);

export default McoAgreementPage;
